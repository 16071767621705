<template>
  <div class="contractdrag jc-c">
    <div>
      <div class="fs-40 fw-b mb-10 dp-fc">{{ state.remainingAmount }}</div>
      <div class="dp-fc c-999">套餐剩余</div> 
    </div>
   <!-- <el-image class="img" :src="require('@/assets/img/home/contractbg.png')" /> -->
    <!-- <el-upload ref="uploadRef" class="upload-demo" drag action="#" :accept="file_accept"
      :http-request="httpRequest" multiple>
      <div class="middle" v-show="!state.nowFile">
        <div class="mb-20">拖动合同至此进行验签</div>
        <oabutton title="合同验签" CSStype=8 width=158 height=52 style="border-radius: 8px;"></oabutton>
      </div>
    </el-upload> -->
  </div>
</template>
<script setup>
import { reactive, ref, unref, nextTick } from "vue";
import { httpToken } from "@/utils/request";
const confirmRef=ref()
const state = reactive({
  remainingAmount: 0,
});

// 获取账户信息
const getAccount = () => {
  // countLoading.value = true
  // console.log("userinfo: ", userInfo)
  httpToken({
    method: "post",
    url: '/admin/account/getAccount',
  }).then(res => {
    state.remainingAmount = res.data.remainingAmount
  }).catch((err) => {
    // countLoading.value = false;
  })
}
getAccount()

</script>

<style lang="scss" scoped>
@import "@/styles/color/value.scss";
.contractdrag {
  position: relative;
  width: 230px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  // background: linear-gradient(180deg, #EEEEEE 0%, rgba(40, 144, 255, 0.14) 0%, #fff 100%);
  background: radial-gradient(circle at 10% 20%, $active-alpha 0%, #FFFFFF 100%);
  border: 1px solid $active-alpha;

  .title {
    align-items: center;
  }
  .information {
    // padding: 30px 30px 23px;
    // align-items: center;
    display:contents;
  }

  .img {
    width: 140px;
    height: 153px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
}</style>
